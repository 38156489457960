{/* <script src="http://maps.google.com/maps/api/js?libraries=drawing"></script> */ }

var drawingManager;
var all_overlays = [];
var selectedShape;
var colors = ['#1E90FF', '#FF1493', '#32CD32', '#FF8C00', '#4B0082'];
var selectedColor;
var colorButtons = {};
var myData = [];

function clearSelection() {
    if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
    }
}

function setSelection(shape) {
    clearSelection();
    selectedShape = shape;
    shape.setEditable(true);
    // selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
    if (selectedShape) {
        selectedShape.setMap(null);
    }
}

function deleteAllShape() {
    for (var i = 0; i < all_overlays.length; i++) {
        all_overlays[i].overlay.setMap(null);
    }
    all_overlays = [];
}

function setSelectedShapeColor(color) {
    if (selectedShape) {
        if (selectedShape.type == google.maps.drawing.OverlayType.POLYLINE) {
            selectedShape.set('strokeColor', color);
        } else {
            selectedShape.set('fillColor', color);
        }
    }
}

function makeColorButton(color) {
    var button = document.createElement('span');
    button.className = 'color-button';
    button.style.backgroundColor = color;
    google.maps.event.addDomListener(button, 'click', function () {
        setSelectedShapeColor(color);
    });

    return button;
}

function initialize(map) {

    // var polyOptions = {
    //     strokeWeight: 0,
    //     fillOpacity: 0.45,
    //     editable: true
    // };
    // Creates a drawing manager attached to the map that allows the user to draw
    // markers, lines, and shapes.
    // drawingManager = new google.maps.drawing.DrawingManager({
    //     drawingMode: google.maps.drawing.OverlayType.POLYGON,
    //     markerOptions: {
    //         draggable: true
    //     },
    //     polylineOptions: {
    //         editable: true
    //     },
    //     rectangleOptions: polyOptions,
    //     circleOptions: polyOptions,
    //     polygonOptions: polyOptions,
    //     map: map
    // });

    const options = {
        drawingControl: true,
        drawingControlOptions: {
            drawingModes: ["polygon"]
        },
        polygonOptions: {
            draggable: true,
            editable: true
        },
        drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);


    google.maps.event.addListener(drawingManager, 'overlaycomplete', function (e) {
        all_overlays.push(e);
        if (e.type != google.maps.drawing.OverlayType.MARKER) {

            drawingManager.setDrawingMode(null);

            var newShape = e.overlay;
            newShape.type = e.type;
            google.maps.event.addListener(newShape, 'click', function () {
                setSelection(newShape);
            });
            setSelection(newShape);
        }
    });

    google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
        // document.getElementById('info').innerHTML += "polygon points:" + "<br>";
        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            myData.push(polygon.getPath().getAt(i).toUrlValue(6));
        }
        console.log(myData);
    });

    drawingManager.setMap(map);

}